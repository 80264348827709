@import "../../utils/colors.scss";

.dropdown-list {
    margin: 8px;
    min-width: 120px;
    max-width: 100%;

    .MuiInput-underline::before {
        bottom: 5px;
    }

    label+.MuiInput-formControl {
        margin-top: 12px;
    }

    .MuiInputBase-root {
        .MuiSvgIcon-root.MuiSelect-icon {
            color: $apex-teal-1;
        }

        .MuiSelect-icon {
            right: -5px;
        }
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-width: 1px !important;
    }

    .MuiInput-underline:after {
        border-bottom: none !important;
    }

    .MuiSelect-root {
        &.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
            padding-right: 30px;
            font-weight: bolder;
            color: $apex-blue;
        }
    }

    .MuiListItem-root.Mui-selected {
        font-weight: bolder;
    }

    .MuiInputLabel-root {
        font-size: 12px;
    }
}

.MuiPopover-root {

    .MuiPaper-rounded {
        border-radius: 2px;
    }

    .MuiPaper-elevation8 {
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03), 0px 3px 14px 2px rgba(0, 0, 0, 0.03);
    }

    .MuiList-root {
        color: $apex-blue;

        .Mui-selected {
            font-weight: bolder;
            background: inherit;
        }

        .MuiListItem-button:hover {
            background: #44546A09;
        }
    }
}