@import "../../utils/colors.scss";

.modal{
    width: 90%;
    max-width: 500px;
    height: auto;
    display: inline-block;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 5px rgba(#000, 0.2);
    border-radius: 5px;
    background-color: rgba(#fff, 1);
    transition: all 250ms;
    padding: 10px 0;
    

    &.hide{
        visibility: hidden;
        background-color: rgba(#fff, 0);
        transform: translate(-50%, -20%);
    }

    &__close-btn{
        font-weight: bolder;
        position: absolute;
        top: 0;
        right: 5px;
        cursor: pointer;
        color: $color-dark-red;
    }
    
    &__title{
        color: $apex-teal-1;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
    }

    &__scrollable{
        max-height: 250px;
        overflow-y: auto;
    }

    &__search-result{
        font-weight: bold;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 80%;
        margin: auto;
        cursor: pointer;

        &-name{
            flex: 0.6
        }
        &-project{
            color: $apex-blue;
            flex: 0.3;
        }

        &:hover, &:hover > &-project{
            background-color: $apex-blue;
            color: $apex-white;
        }
    }
}

.overlay{
    background-color: rgba($color: #000000, $alpha: 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    height: 100%;
    transition: all 250ms;

    &.hide{
        visibility: hidden;
        background-color: rgba($color: #000000, $alpha: 0);
    }
}