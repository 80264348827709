.resultContainer{
    margin: 10px 0;
    height: auto;

    .filter{
        font-size: 18px;
        font-weight: bold;
        color: #000;
        text-align: left;
        padding-left: 20%;
        padding-bottom: 20px;

    }

    .itemResult{
        text-decoration: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        padding: 2px 0;
        cursor: pointer;
        text-align: left;

        .consultantName{
            color: #555555;
            flex: 0.5;
            padding-left: 8%;
        }
        .consultantProject{
            color: #000000;
            font-weight: bold;
            font-style: italic;
            flex: 0.3
        }
        
        .statusCircle{
            width: 10px;
            height: 10px;
            border-radius: 5px;
        }

        .statusCircle.active{
            background-color: #268741;
        }

        .statusCircle.inactive{
            background-color: #AF1515;
        }

        &:hover{
            background-color: #454545;
            .consultantName, .consultantProject{
                color: white;
            }
        }
    }
    
    & .noResults{
        font-size: 14px;
    }
    & .error {
        font-size: 14px;
        color: #AF1515;
    }

    &:not(:first-of-type){
        margin-top: 25px;
    }
}