.draw-menu {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgb(246, 249, 253);
  border-radius: 6px;
  height: 95vh;
  max-height: calc(100vh - 110px);
  z-index: 3;

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }

  .draw-menu__item {
    margin: 18px 2px;

    &.selected {
      width: 100%;
      box-sizing: border-box;
      margin-right: 0;
      margin-left: 0;
      padding: 6px;
      background: rgb(82, 104, 138);

      g,
      path {
        stroke: #fff;
      }
    }
  }
}
