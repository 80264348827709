@import "../../utils/colors.scss";

@mixin bellow600 {
    @media (max-width: 600px) {
        @content;
    }
}

.menuLink {
    text-decoration: none;
    color: $apex-blue;
}

.headerRoot {
    justify-content: space-between;

    .appBar {
        padding: 0 !important;
        height: 80px;
        background-color: $apex-white;
        position: static;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);

        .toolBarContainer {
            justify-content: space-between;
            color: #8c969b;
            max-width: 1700px;
            width: 100%;
            margin: auto;
            padding: 0;
            background-color: none;

            .logoWrapper {
                flex: 0.13;
                margin: auto 16px;
                min-width: 160px;

                .logoWrapperLink {
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    text-decoration: none;
                    cursor: pointer;

                    .lmsIcon {
                        display: flex;
                        justify-content: flex-start;
                        height: 50px;
                        width: 50px;
                    }

                    .lmsText {
                        color: $apex-blue;
                        font-size: 12px;
                        text-align: start;
                        text-decoration: none;
                        text-transform: uppercase;
                        width: 80px;
                        margin-left: 10px;
                    }
                }

                .maxLogo {
                    max-width: 100%;
                    max-height: 100%;
                    min-width: 150px;

                    @include bellow600 {
                        display: none;
                    }
                }

                .minLogo {
                    display: none;
                    width: 100%;
                    height: auto;
                    min-width: 70px;

                    @include bellow600 {
                        display: block;
                    }
                }
            }

            .hide {
                display: none !important;
            }

            .show {
                display: block !important;
            }

            .searchWrapper {
                font-family: "Libre Franklin";
                display: flex;
                border-radius: 4px;
                margin-right: 10px;
                width: 100%;
                flex: 0.8;
                padding: 5px 0;
                background-color: #f0f0f0;
                position: relative;
                align-items: center;

                .searchResults {
                    width: 100%;
                    flex: 1;
                    position: absolute;
                    height: auto;
                    z-index: 8;
                    top: 100%;
                    background-color: #f0f0f0;
                    box-shadow: 7px 10px 11px 1px rgba(56, 56, 56, 1);
                    overflow-y: scroll;
                    max-height: 330px;
                }

                .dismiss {
                    font-size: 15px;
                    font-weight: bold;
                    cursor: pointer;
                    display: block;
                    height: 100%;
                    margin-right: 10px;
                    user-select: none;
                }

                .searchIcon {
                    height: 18px;
                    margin: auto;
                    padding: 0 10px;
                    width: 18px;

                    @include bellow600 {
                        padding: 0;
                    }
                }

                .inputRoot {
                    cursor: text;
                    display: inline-flex;
                    position: relative;
                    font-size: 1rem;
                    box-sizing: border-box;
                    align-items: center;
                    font-family: "Libre Franklin";
                    line-height: 1.1875em;
                    width: 100%;

                    .inputText {
                        color: #4f4f4f;
                        width: 100%;
                    }
                }
            }

            .profileWrapper {
                max-width: 150px;
                margin: 5px;
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex: 0.1;

                .gearButton {
                    $gearSize: 30px;
                    width: $gearSize;
                    height: $gearSize;
                    cursor: pointer;

                    @include bellow600 {
                        $gearSize: 30px;
                        width: $gearSize;
                        height: $gearSize;
                    }
                }

                .profileButton {
                    $profileBtnSize: 58px;
                    width: $profileBtnSize;
                    height: $profileBtnSize;
                    border-radius: 50%;
                    color: $apex-blue;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    span {
                        color: white;
                    }

                    @include bellow600 {
                        $profileBtnSize: 40px;
                        font-size: 18px;
                        width: $profileBtnSize;
                        height: $profileBtnSize;
                    }
                }
            }
        }
    }
}