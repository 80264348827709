@import "./utils/colors.scss";

body {
  margin: 0;
  font-family: "Libre Franklin";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $apex-background-clear-grey;

  code {
    font-family: "Libre Franklin";
  }

  .MuiButton-label {
    font-family: "Libre Franklin";
    font-size: 16px;
  }
}