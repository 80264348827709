#modal {
  button {
    padding: 1px 28px !important;
    text-transform: capitalize !important;
  }

  .MuiFormControlLabel-label {
    font-size: 14px !important;
  }
}
