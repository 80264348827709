$some-yellow: #EDAA48;

$apex-blue: #44546A;
$apex-teal-1: #37B3A2;
$apex-teal-2: #9FE2D8;
$apex-teal-3: #EAFDF8;

$apex-orange-1: #E7792B;
$apex-orange-2: #EE9F2D;
$apex-orange-3: #F7C85E;
$apex-yellow: #F9E661;

$apex-logo-grey: #7C95A5;
$apex-grey-1: #808083;
$apex-grey-2: #D2DDE8;
$apex-grey-3: #F8FAFD;
$apex-white: #FFFFFF;

$color-dark-red: #D14732;

$apex-background-clear-grey: #EFF0F2;

$dark-shadow: #00000029;
